import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout'

export const query = graphql`
  query($id: ID!) {
    gcms {
      page(where: { 
        id: $id
       }) {
         id
        title
        content {
          html
        }
        slug
        translations
        localizations {
          id
          title
          content {
            html
          }
          locale
          slug
          translations
        }
      }
    }
  }
`;


export default ({ data, pageContext }) => {
  const pageData = pageContext.locale === 'en' ?  data.gcms.page : data.gcms.page.localizations[0]
  const otherSlug = pageContext.locale === 'en' ? "/sv/" +  data.gcms.page.localizations[0].slug : "/" + data.gcms.page.slug
  
  return (
  <Layout otherSlug={otherSlug}>
  <div style={{ maxWidth: 600, margin: '5rem auto', width: '90%' }} className="bg-gray-500">
    <h1>{pageData.title}</h1>
   <h2>{pageData.translations.helloworld}</h2>

    <div dangerouslySetInnerHTML={{ __html: pageData.content.html }} />

    <Link to="/">Back to all pages</Link>
  </div></Layout>)
}
